// IMPORTS
@import (reference) "../settings.less";

// ROUND BANNER
.round-banner {

    .headline {
        text-align: center;
    }

    .wrapper {
        .grid();
        gap: 26px 24px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    a {
        position: relative;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 50%;

        @media (min-width: 1280px) {
            &:hover {

                .text-wrapper {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(132, 194, 61, 0.9);
                    transition: all .2s ease-in-out;
                }
            }
        }

        .text-wrapper {
            height: 72px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            text-align: center;
            overflow: hidden;
            background-color: #84C23E;
            color: white;
            font-weight: bold;
            padding: 10px 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .15s ease-in-out;

            span {
                display: flex;
                line-height: 1.2;
                transition: all .2s ease-in-out;
                max-height: calc(2 * (1.2 * 16px));
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box !important;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: all .2s ease-in-out;
                position: relative;
                top: -7px; // to  visually center
            }
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}

// NEWS BANNER
.news-banner {
    grid-column: 1 / -1;

    .wrapper {
        .grid();
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 48px 24px;
    }

    article {
        display: grid;
        gap: 24px;
        grid-template-rows: auto 1fr;
    }

    .news-image-link {
        overflow: hidden;
        border-radius: var(--borderRadius);
        width: 100%;
        aspect-ratio: 464 / 230;

        @media (min-width: 1280px) {
            &:hover {

                img {
                    transform: scale(1.04);
                }
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .2s ease-in-out;
        }
    }

    .news-name-link {
        font-weight: bold;
        color: @header-color;

        @media (min-width: 1280px) {
            &:hover {
                text-decoration: underline;
            }
        }

        & + p {
            margin-top: 8px;
        }
    }

    .news-more-link {
        color: @main-color;
        .line-height();
        display: flex;
        gap: 12px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        &:before {
            content: '\e917';
            font-family: icomoon;
            float: left;
            font-weight: normal;
            color: white;
            background-color: @main-color;
            width: 24px;
            height: 24px;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            transform: rotate(-90deg);
        }

        @media (min-width: 1280px) {
            &:hover {
                color: @sec-color;

                &:before {
                    background-color: @sec-color;
                }
            }
        }
    }
}

// MAIN GREETING
.main-greeting {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    position: relative;

    &:after {
        content: '';
        .center-lr();
        top: 0;
        width: 101vw;
        height: 100%;
        z-index: -1;
        background-color: #D7E7C5;
    }

    .text-wrapper {
        flex-grow: 1;
        padding: 30px 0;
        
        .headline {
            margin-bottom: 0;

            & + .user-custom-content {
                margin-top: 24px;
            }
        }
    }

    .img-wrapper {
        flex-shrink: 0;
        width: 40.625%;
        margin-left: 18.8%;
        min-height: 468px;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            height: 100%;
        }
    }
}